import React, { useState, useContext } from "react";
import FA from "react-fontawesome";
import { Link } from "gatsby";
import { Layout,Drawer } from "antd";
import "font-awesome/less/font-awesome.less";
import style from "./header.module.less";
import "../../../styles/global.less";
import { useWindowSize } from "../../../utils/hooks";
import {getCookie} from "../../../utils/cookies"

import { AlternateLinksContext } from "../../wrapWithI18nProvider";
import { useTranslation } from "react-i18next";

const Header = props => {
  const [menu, setMenu] = useState(false);

  // ******** REFERAL Cokies***********
  if (typeof window !== `undefined`) {
    var vallCookie = document.cookie;
    var isOpenBanner = true; //отключен баннер
    // isOpenBanner = getCookie('isOpenBannerT') != undefined;
    
    if(!isOpenBanner) document.cookie = `isOpenBannerT=${true};max-age=259200;path=/`;

    let params = new URLSearchParams(document.location.search.substring(1));
    let ref = params.get("ref");

    if (ref != null) {
      document.cookie = `_cr=${ref};max-age=259200;path=/`;
    }
  }
  // ******** REFERAL Cokies***********
  const[isVisibleBottomBanner, setVisible]  = useState(isOpenBanner ? false : true);
  const onClose = () => {
    setVisible(false);
  };

  const alternateLinks = useContext(AlternateLinksContext);
  const { t, i18n } = useTranslation("common");

  const [width] = useWindowSize();

  const toggleMenu = () => {
    if (width !== 0 && width <= 768) {
      if (menu) {
        setMenu(false);
      } else {
        setMenu(true);
      }
    }
  };

  const items = props.MenuItems.map(item => (
    <li className={item.externUrl === null ? style.navItem : style.navItemBtn}>
      {item.externUrl === null ? (
        <Link
          to={`${item.path === null ? "" : item.path}/${i18n.language}`}
          onClick={toggleMenu}
          activeClassName={style.anchorActive}
        >
          {" "}
          {item.title.translate}
        </Link>
      ) : (
        <a href={item.externUrl} onClick={toggleMenu}>
          {" "}
          {item.title.translate} <FA name="sign-in" />{" "}
        </a>
      )}
    </li>
  ));
  return (
    <>
      <div
        className={style.circleMenu}
        role="button"
        tabIndex="0"
        onKeyDown={toggleMenu}
        onClick={toggleMenu}
      >
        <div className={`${style.hamburger} ${menu ? style.menuIcon : null}`}>
          <div className={style.line} />
          <div className={style.line} />
          <div className={style.hamburgerText}>{t("menuTxt")}</div>
        </div>
      </div>
      <Drawer
          placement='bottom'
          height={width<=768 ? 200 : 130}
          closable={true}
          onClose={onClose}
          visible={isVisibleBottomBanner}
          key='bottom'
        >
          <h3 className={style.textCenter}><strong>{t("tiksmile")}</strong>{t("tiksmile_desc")}</h3>
          
        <div className="centerAlign"><a href="https://bit.ly/3madcUP"><img width="175" src="https://videovtope.likeobmen.ru/img/store/google.png"></img></a></div>
    
        </Drawer>

      <Layout
        className={`${style.navWrap} ${menu ? null : style.hidden} ${
          menu ? style.openMenu : null
        }`}
      >
        <div className={style.backgroundDiv}>
          <ul className={style.nav}>
            {items}

            <li className={style.navLanguage}>
              {alternateLinks &&
                alternateLinks
                  .filter(link => link.language !== i18n.language)
                  .map((link, i) => [
                    i > 0 && " | ",
                    <Link
                      to={link.path}
                      activeClassName={style.navLanguage}
                      hrefLang={link.language}
                    >
                      <FA name="globe" />
                      {t(link.language)}
                    </Link>
                  ])}
            </li>
          </ul>
        </div>
      </Layout>
    </>
  );
};

export default Header;
