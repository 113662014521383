/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({
  title,
  description,
  path,
  lang,
  keywords,
  contentType,
  imageUrl,
  translations,
  meta
}) {
  const { site, file } = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "icon" }) {
          childImageSharp {
            fixed(width: 600) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaKeywords =
    keywords && keywords.length > 0
      ? { name: "keywords", content: keywords.join(", ") }
      : [];
  const pageUrl =
    path !== ""
      ? `${site.siteMetadata.siteUrl}/${path}`
      : `${site.siteMetadata.siteUrl}`;

  let metaImageUrl = file.childImageSharp.fixed.src;
  if (imageUrl !== undefined && imageUrl !== null) {
    metaImageUrl = imageUrl;
  }

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },

        /* Open Graph */
        { property: "og:title", content: title },
        { property: "og:type", content: contentType || "website" },
        { property: "og:url", content: `${pageUrl}/${lang}` },
        { property: "og:description", content: metaDescription },
        { property: "og:image", content: metaImageUrl },
        { property: "og:image:alt", content: metaDescription },
        { property: "og:site_name", content: site.siteMetadata.title },
        { property: "og:locale", content: lang || "en" },
        /* Twitter card */
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: metaDescription },
        { name: "twitter:image", content: metaImageUrl },
        { name: "twitter:image:alt", content: metaDescription },
        { name: "twitter:site", content: site.siteMetadata.siteUrl },
        { name: "twitter:creator", content: site.siteMetadata.author }
      ]
        .concat(metaKeywords) // Keywords
        .concat(meta)}
      link={[{ rel: "canonical", href: `${pageUrl}/${lang}` }] // Canonical url добавил код языка, так как нет редиректов
        // Translated versions of page
        .concat(
          translations
            ? translations.map(obj => ({
                rel: "alternate",
                hreflang: obj,
                href: `${pageUrl}/${obj}`
              }))
            : []
        )}
    />
  );
}

SEO.defaultProps = {
  lang: "en",
  contentType: "website",
  imageUrl: null,
  keywords: [],
  translations: ["en", "ru"],
  meta: []
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  lang: PropTypes.string,
  contentType: PropTypes.oneOf(["article", "website"]),
  imageUrl: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  translations: PropTypes.arrayOf(
    PropTypes.shape({
      hreflang: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired
    })
  ),
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      property: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  )
};

export default SEO;
